body,h1,h2,h3,h4,h5,h6,p,span,input,a {
  color: #1A1A1C;
  font-family: "Roboto", Sans-serif;
}

input[type="image"] {
  max-width: 100%;
}

button {
  cursor: pointer;
}

.hidden {
  display: none!important;
}

/* MAIN STYLES */
body {
  background-color: hsl(0,0%,97%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

div#root {
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.App {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100vw;
}

.App-box {
  display: flex;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

.App-box::-webkit-scrollbar {
  width: 0px;
  display: none;
}

/* Track */
.App-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.App-box::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.App-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.core {
  width: 100%;
}

.helper-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  z-index: 999999;
}

.helper-wrapper-box {
  flex: 1 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.core-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  min-height: -moz-fit-content;
  min-height: fit-content;
}