.App-box.dark-mode {
    background: #54595F;
}

.dark-mode .main-wow-image {
    z-index: 0;
}

.dark-mode .main-wow-text {
    z-index: 100;
}

.dark-mode .menu.menu-blured {
    background: rgba(36, 37, 37, 0.67);
}

.dark-mode .menu-blured button.button-box {
    color: rgb(229, 224, 216);
}

.dark-mode div {
    color: rgb(229, 224, 216);
    border-color: rgb(229, 224, 216)!important;
}

.dark-mode .menu-drawer-main {
    background: #54595F;
}

.dark-mode .input.input-pref input {
    background: #54595F;
    color: white;
}

.dark-mode .input-submit-pref button {
    border-color: rgb(229, 224, 216);
    color: rgb(229, 224, 216)!important;
}

.dark-mode .input-submit-pref button:hover {
    background: rgb(229, 224, 216);
    color: rgba(0,0,0,.9)!important;
}

.button.input-submit-pref .button-box:hover {
    color: white;
}

.dark-mode .input.input-pref input:focus {
    border-color: white;
}

.mode-chooser {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1000;
    width: 120px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mode-chooser-box {
    display: flex;
    width: 60px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 20px;
    background: white;
    padding: 2px;
    box-sizing: border-box;
    background: #303030;
}

.mode-circle {
    width: 26px;
    height: 26px;
    position: absolute;
    background: white;
    border-radius: 20px;
    left: 2px;
    transition: left .3s ease;
}

.mode-icon {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark-mode .mode-circle {
    left: 32px;
    background: black;
}

.dark-mode .mode-chooser-box {
    background: #363739;
}

.mode-icon.white-moon {
    color: white;
}

.dark-mode .css-ul07u9 {
    background: #55595f;
}

.dark-mode .css-tzw708-menu {
    background: hsl(0,0%,20%);
}