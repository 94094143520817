.button, .button>button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}


.main-wow {
    height: 100vh;
    /* margin-top: -70px; */
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.main-wow-container {
    flex: 1;
    height: 100vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    position: relative;
    overflow: hidden;
}

.main-wow-text {
    display: flex;
    flex-direction: column;
    /* margin: 0 3.8rem; */
    margin: 0 50px;
    margin-top: -1.5rem;
    justify-content: flex-start;
    color: white;
}

.main-wow-sm-title {
    font-family: "Roboto", Sans-serif;
    font-size: 20px;
    font-weight: 300;
}

.main-wow-beauty-text {
    font-family: "SSL", Sans-serif;
    font-size: 72px;
}

.main-wow-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    transition: all .3s ease;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.main-wow-image.main-wow-selected {
    opacity: 1;
}

.main-wow-image > input {
    max-width: unset;
    min-height: 100%;
    min-width: 100%;
    /* max-height: 100vh; */
}

.main-wow-image-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
}

.wow-text {
    margin-top: 120px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

.wow-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 0 1rem;
}

.wow-text-title {
    font-family: "SSL", Sans-serif;
    font-size: 62px;
    margin-bottom: 20px;
}

.wow-text-description {
    margin: 0 60px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
}

.wow-carousel {
    display: flex;
    justify-content: center;
}

.wow-carousel-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.wow-carousel-buttons {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
}

.wow-carousel-gallery::-webkit-scrollbar {
    display: none;
    height: 0;
}
.wow-carousel-gallery {
    display: flex;
    width: 100vw;
    height: 80vh;
    overflow: auto;
    /* padding: 0 2.5rem; */
    box-sizing: border-box;
    scroll-behavior: smooth;
}
.p02{
    padding: 0 2rem;
}
.wow-carousel-gallery-container {
    display: flex;
    align-items: center;
    /* padding: 0 2rem; */
}

.wow-carousel-gallery-item {
    width: 100vw;
}

.wow-carouse-gallery-item-container {
    padding: 0 2.5rem;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
}

.wow-carousel-buttons-item {
    width: 2.8rem;
    height: 3.5rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background: hsl(0,0%,10%);
    color: hsl(0,0%,95%);
    font-size: 22px;
    box-sizing: border-box;
}

.wow-carousel-buttons-item:nth-child(1) {
    padding-left: .5rem;
    justify-content: flex-start;
    z-index: 100;
}

.wow-carousel-buttons-item:nth-child(2) {
    padding-right: .5rem;
    justify-content: flex-end;
    z-index: 100;
}


.wow-connect {
    display: flex;
    height: calc(100vh - 100px);
    margin-top: 120px;
    /* justify-content: space-between; */
}

.wow-connect-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.wow-connect-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    padding-left: 5rem;
    box-sizing: border-box;
    flex: 1;
}

.wow-connect-info {
    margin: 40px 80px 80px 20px;
    box-sizing: border-box;
    width: 35%;
    display: flex;
}

.wow-connect-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wow-gallery-item-box input[type="image"] {
    max-width: unset;
}

.wow-connect-title {
    font-family: "SSL", Sans-serif;
    font-size: 62px;
}

.wow-connect-description {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
}

.wow-gallery {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    height: 75vh;
    overflow: hidden;
    width: 100%;
}

.wow-gallery-box {
    position: relative;
    width: 100%;
}

.wow-gallery-photos {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.wow-gallery-item {
    display: none;
}

.wow-gallery-item.wow-selected {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.button.connect-button {
    display: flex;
}

.button.connect-button button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
}

.button.connect-button .button-title {
    font-family: "Open Sans", Sans-serif;
    font-size: 18px;
    font-weight: 300;
}

.button.connect-button .button-icon {
    margin-left: .3rem;
    margin-top: .3rem;
}

.input-box input {
    border: none;
    padding: .3rem;
    margin-bottom: 1.75rem;
    width: 100%;
    font-family: "Open Sans";
    font-size: 13px;
}

.input.input-pref input {
    border-bottom: 2px solid rgba(180,180,180,.2);
    transition: all .3s ease;
}

.input.input-pref input:focus {
    border-bottom: 2px solid rgba(0,0,0,.5);
}

textarea:focus, input:focus{
    outline: none;
}


.input-title-box {
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: 100;
    color: black;
}


.input-submit-pref button {
    background: none;
    border-radius: 20px;
    border: 1px solid rgba(0,0,0,.4);
    margin-bottom: 0;
    transition: all .3s ease;
}


.input-submit-pref button:hover {
    background: rgba(0,0,0,.9);
    color: white;
    cursor: pointer;
}

.button.input-submit-pref {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button.input-submit-pref .button-box {
    width: 90%;
    height: 30px;
    font-family: Open sans;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: hsl(0,0%,10%);
}

.input-submit-pref .button-title {
    color: inherit!important;
}

.main-wow-lines {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 75px;
    display: flex;
    align-items: center;
}

.main-wow-lines-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.wow-main-line {
    height: 2px;
    margin: .65rem 0;
    background: rgba(180,180,180,0.7);
    width: 60%;
    transition: width .5s ease;
    display: flex;
    justify-content: flex-end;
}

.wow-main-line.wow-main-selected {
    width: 100%;
}

.wow-main-line-box {
    height: 2px;
    background: white;
    width: 0;
    transition: width 0s linear;
}

.wow-main-line.wow-main-selected .wow-main-line-box {
    width: 100%;
    transition: width 6s linear;
}

.wow-main-line-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.wow-main-line-container:hover .wow-main-line:not(.wow-main-selected) {
    width: 75%;
}


.centered-title .main-wow-container {
    justify-content: center;
}

.details-photo .main-wow-image > input {
    max-width: 100%;
}

.css-hlgwow {
    display: contents!important;
}

.input-box.input-box-select {
    margin-bottom: 1.75rem;
}
.wow-connect-container-beforeafter {
    position: relative;
    width: 100%;
    height: 65dvh; /* Adjust as needed */
    overflow: hidden;
}

.wow-before-after {
    position: relative;
    width: 100%;
    height: 100%;
}

.photo-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.photo-before, .photo-after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.photo-before {
    clip-path: inset(0 50% 0 0); /* Initially show only left half */
    z-index: 2;
}

.photo-after {
    z-index: 1;
}

.divider {
    position: absolute;
    top: 0;
    left: 50%;
    width: 4px;
    height: 100%;
    background-color: white;
    transform: translateX(-50%);
    z-index: 3;
}

.draggable {
    position: absolute;
    top: 0;
    left: 50%;
    width: 20px;
    height: 100%;
    cursor: ew-resize;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.5);
    z-index: 4;
    border-radius: 10px;
}

.wow-before-after {
    position: relative;
    width: 100%;
    height: 100%;
    user-select: none; /* Prevents text selection */
}

.photo-before, .photo-after, .divider, .draggable {
    user-select: none; /* Ensures images and elements are not highlighted */
    -webkit-user-drag: none; /* Prevents dragging behavior */
    pointer-events: none; /* Prevents accidental text/image selection */
}

.draggable {
    pointer-events: auto; /* Enables pointer events for dragging */
    cursor: ew-resize;
}
.project-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.project-card {
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 16px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.project-card:hover {
    transform: scale(1.05);
}

.project-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 12px;
}

.image-placeholder {
    width: 100%;
    height: 180px;
    background-color: #f0f0f0;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
}

.project-title {
    font-size: 18px;
    text-align: center;
    color: #333;
    margin: 0;
}

.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 100px;
}

.footer-item-suit-navigations .one-lang-item {
    text-transform: capitalize;
    font-family: 'Open Sans', serif;
    font-size: 18px;
    font-weight: 400;
}

.coming-soon-box {
    /*text-align: center;*/
    /*padding: 40px 60px;*/
    /*color: #333;*/
    /*max-width: 600px;*/
    /*background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(245, 245, 245, 0.8));*/
    /*border-radius: 15px;*/
    /*box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);*/
    /*border: 1px solid rgba(0, 0, 0, 0.1);*/
    /*min-width: 300px;*/
}

.coming-soon-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    animation: bounce 1.5s infinite;
    color: #222;
}

.coming-soon-subtitle {
    font-size: 18px;
    color: #555;
    opacity: 0.9;
    line-height: 1.6;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-8px); }
}
.dark-mode a {
    color: rgb(229, 224, 216);
    border-color: rgb(229, 224, 216) !important;
}
.footer-item-box {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    margin-top: .75rem;
    font-family: 'Open Sans', serif;
    font-size: 18px;
}

.footer-item-box:hover {
    transform: scale(1.05);
}
.contact-info {
    display: flex;
    flex-direction: column;
}

.social-icon {
    display: inline-block;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    width: 28px;
    height: 28px;
    margin-top: .75rem;
}
.wh28 {
    width: 28px;
    height: 28px;
}

.social-icon:hover {
    transform: scale(1.20);
}
.td-none{
    text-decoration: none;
}

.dark-mode a.social-icon img {
    filter: invert(100%);
}

@media screen and (max-width: 600px) {
    .wow-carousel-gallery {
        height: unset;
    }

    .wow-connect-carousel {
        width: 0;
        padding: 0;
    }

    .details-photo .main-wow-image>input {
        max-width: unset;
        min-width: unset;
        max-height: 100%
    }

    .wow-connect-container {
        flex-direction: column;
        flex: unset;
        overflow: hidden;
    }

    .wow-connect-info {
        margin: unset;
        width: 100%;
        order: -1;
    }

    .wow-connect {
        height: unset;
    }

    .wow-connect-carousel {
        width: 100vw;
        padding: 0 1rem;
    }

    .wow-gallery {
        width: 100%;
        height: 50vh;
    }

    .wow-connect-info-container>div {
        margin: 20px 10px;
    }

    .wow-gallery-item-box input[type=image] {
        max-width: 100%;
    }

    .wow-carouse-gallery-item-container {
        padding: 0 2rem;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 800px){
    .main-wow-image>input {
        min-width: unset;
    }
}
