.menu {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    height: 100px;
    z-index: 999;
    transition: background .3s ease, height .5s ease;
}

.menu-box {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 15px 50px 10px 50px;
}

.menu-box-drawer {
    position: fixed;
    z-index: 999999;
    top: 0;
    bottom: 0;
    width: 0;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transition: opacity 1s ease;
}

.menu-box-drawer.menu-drawer-opened {
    width: 100%;
    background: rgba(0,0,0,.4);
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
    opacity: 1;
}

.menu-drawer-main {
    width: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: 0 0 24px 10px rgba(0,0,0,.5);
    transition: width .5s ease, min-width .5s ease;
    min-width: 0;
}

.menu-box-drawer.menu-drawer-opened .menu-drawer-main {
    width: 25vw;
}

.menu-box-drawer.left-menu-box {
    left: 0;
}

.menu-box-drawer.right-menu-box {
    right: 0;
}

.menu-drawer-next {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.menu-box-drawer.right-menu-box .menu-drawer-next {
    order: -1;
}


.menu-drawer-close-box {
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button.quit-menu-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button.quit-menu-button .button-icon {
    width: 300px;
    transition: all .3s ease;
}

.button.quit-menu-button:hover .button-icon {
    transform: scale(1.1);
}

.hooper-item {
    font-weight: 600;
    font-size: 20px;
    transition: all .3s ease;
}

.hooper-item-box {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    min-width: 218px;
}

.menu-drawer-main-box {
    width: 100%;
    padding: .75rem;
}

.hooper-item-box:hover .hooper-item {
    font-size: 24px;
}

.menu-item-button {
    /* padding: 1.5rem 1.5rem; */
    /* width: 15%; */
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-item-button button {
    color: white;
}
.main-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
}

.button.menu-button > button {
    font-family: "Open Sans", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    transition: all .4s ease;
}

.button.menu-button button:hover {
    font-size: 20px;
}

.button.icon-menu-button button {
    font-size: 24px;
    cursor: pointer;
}

.menu-item-button.left-item-button {
    justify-content: flex-start;
}

.menu-item-button.right-item-button {
    justify-content: flex-end;
}

.menu.menu-blured {
    background: rgba(255, 255, 255, 0.67);
    backdrop-filter: saturate(180%) blur(10px);
    height: 70px;
}

.menu-blured button.button-box {
    color: black;
}


.menu-drawer-contact-info {
    flex: 1 1;
    display: flex;
    color: white;
    flex-direction: column;
    padding: 2rem;
    justify-content: center;
}

.menu-drawer-contact-info a {
    color: white;
    text-decoration: none;
    font-family: 'SSL';
    font-size: 55px;
}

.contact-info {
    display: flex;
    flex-direction: column;
}

.contact-info-box {
    display: flex;
    flex: 1;
}

.contact-info-section {
    display: flex;
    flex-direction: column;
    width: calc(50% - .5rem);
    box-sizing: border-box;
}

.contact-info-section-title {
    font-family: "Open Sans";
    font-weight: lighter;
}

.contact-info-value {
    display: flex;
    align-items: center;
}

.contact-info-value-img {
    font-size: 20px;
    margin-right: 15px;
}

.menu-box-drawer.right-menu-box .menu-drawer-main {
    right: 0;
}
.menu-box-drawer.right-menu-box .menu-drawer-next {
    left: 0;
    right: unset;
}

.menu-drawer-opened .language-menu {
    position: fixed;
}
.language-menu {
    top: 0;
    width: 25vw;
}

.language-menu-box {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}
.language-menu-line {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.75rem 0;
}

.one-lang-item {
    padding: .1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 .1rem;
    font-size: 18px;
    font-family: Open sans;
    font-weight: 100;
    text-transform: uppercase;
    cursor: pointer;
}

.one-lang-item:hover {
    font-weight: 400;
}

.one-lang-item.lang-selected {
    font-weight: 600;
}

.right-menu-box .language-menu {
    right: 0;
}
.left-menu-box .language-menu {
    left: 0;
}

@media screen and (max-width: 600px){
    .language-menu {
        width: 58vw;
    }
    .menu-item-button {
        width: 26vw;
    }

    .menu-box-drawer.menu-drawer-opened .menu-drawer-main {
        width: 58vw;
        min-width: 58vw;
        position: fixed;
    }

    .button.quit-menu-button .button-icon {
        width: 80px;
    }

    .contact-info {
        flex-direction: column;
    }

    .contact-info-section {
        width: 100%;
    }

    .menu-drawer-contact-info a {
        font-size: 40px;
    }

    .hooper-item-box {
        height: 90px;
    }

    .menu-drawer-main-box {
        min-width: 145px;
        box-sizing: border-box;
    }

    .contact-info-value-img {
        display: none;
    }

    .menu-drawer-contact-info {
        padding: .375rem;
    }

    .menu-drawer-opened .menu-drawer-next {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        width: 42vw;
        z-index: 999;
    }


    .main-logo {
        width: 90px;
    }

    .menu-box {
        padding: 15px 20px 10px 20px;
    }

    .button.menu-button > button {
        font-size: 14px;
    }

    .left-item-button button.button-box {
        font-size: 20px;
    }

    .right-item-button button.button-box {
        margin-top: 3px;
    }
}
