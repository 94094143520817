.footer {
    margin-top: 120px;
    /* margin-bottom: 20px; */
    /* padding-bottom: 60px; */
}

.footer-box {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    align-items: flex-end;
}

.footer-body {
    display: flex;
    /* flex: 1; */
    justify-content: fl;
    width: 100%;
    border-top: 1px solid black;
    margin-top: 19px;
    /* height: 70vh; */
}
.fwrap{
    flex-wrap: wrap;
}
.footer-body-center-box {
    display: flex;
    flex: 1;
    justify-content: space-between;
    /* width: 80%; */
}

.footer-bottom {
    width: 20%;
    order: -1;
}

.footer-item-suit-title {
    font-family: 'Roboto';
    font-size: 12px;
    margin-top: 20px;
}

.footer-item-suit {
    /* margin-right: 4em; */
    flex: 1;
    min-width: 250px;
}

.footer-item-suit.map-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer-item {
    margin-top: .75rem;
    font-family: 'Open Sans';
    font-size: 18px;
}

.footer-top {
    /* width: 200px; */
    /* height: 200px; */
    order: 1;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 40px;
}
/* Стиль для компонента MapPhotoView */
.map-photo-view {
    width: 100%;
    max-width: 300px;
    height: auto;
    aspect-ratio: 3 / 2; /* Зберігає пропорції 3:2 */
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.map-photo-view:hover {
    transform: scale(1.05);
}

.map-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.map-address {
    font-family: 'Roboto', serif;
    font-size: 12px;
    color: #555;
    text-align: right;
    margin-bottom: 5px;
}
.map-coordinates {
    font-family: 'Roboto', serif;
    font-size: 12px;
    color: #555;
    text-align: right;
    margin-bottom: 10px;
}


@media screen and (max-width: 800px) {
    .footer-item-suit.map-container {
        align-items: flex-start;
    }
}