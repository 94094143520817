/* Загальні стилі */
.review-list{
    padding: 15px 0;
}
.jc-fe {
    display: flex;
    justify-content: flex-end;
}
.review-list-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0 2rem;
    justify-content: flex-start;
}
.review-item {
    flex: 1 1 calc(30% - 2rem); /* Робить кожен відгук шириною 33% від контейнера з урахуванням відступів */
    /*max-width: 300px; !* Максимальна ширина відгуку *!*/
    background-color: #fff;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}
.dark-mode .review-item {
    background-color: rgba(36, 37, 37, 0.67);; /* Темний фон */
    border: 1px solid #ffffff;
}

/* Заголовок відгуку */
.review-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.review-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
}

.review-info {
    flex: 1;
}

.review-name {
    font-family: "Roboto", sans-serif;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.dark-mode .review-name {
 color: white;
}

.review-stars {
    display: flex;
}

.star {
    font-family: "SSL", Sans-serif, serif;
    font-size: 20px;
    color: #ccc;
    margin-right: 4px;
}

.star.filled {
    color: #ffb400;
}

.review-content {
    font-size: 16px;
    margin-bottom: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
}
.dark-mode .review-content {
    color: white;
}

.review-date {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #1A1A1C;;
    font-weight: 500;
}
.dark-mode .review-date {
    color: white;
}

.reviews-title {
    font-family: "SSL", Sans-serif, serif;
    font-size: 62px;
    color: #1A1A1C;;
    padding: 0 2rem;
    padding-bottom: 15px;
}
.dark-mode .reviews-title  {
    color: white;
}


/* 🎯 Адаптація для мобільних пристроїв */
@media (max-width: 768px) {
    .review-item {
        padding: 16px;
        max-width: 100%;
    }

    .review-avatar {
        width: 40px;
        height: 40px;
        margin-right: 8px;
    }

    .review-name {
        font-size: 16px;
    }

    .review-content {
        font-size: 14px;
    }

    .review-date {
        font-size: 12px;
    }

    .star {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .review-item {
        padding: 12px;
    }

    .review-avatar {
        width: 36px;
        height: 36px;
    }

    .review-name {
        font-size: 14px;
    }

    .review-content {
        font-size: 13px;
    }

    .review-date {
        font-size: 11px;
    }

    .star {
        font-size: 16px;
        margin-right: 2px;
    }
}
